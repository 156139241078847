import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '@environment';
import { NavController } from '@ionic/angular';
import { StorageKey } from '@models/storage-key.model';
import { UserInfo } from '@models/user-info.model';
import { firstValueFrom } from 'rxjs';
import { setUser } from 'src/sentry';

export const userAPIUrl = `${environment.appUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  #userInfo = signal<UserInfo | null>(null);
  public readonly userInfo$ = this.#userInfo.asReadonly();

  constructor(
    private readonly http: HttpClient,
    private readonly navController: NavController
  ) {}

  async fetchCurrentUserInfo(): Promise<void> {
    try {
      const req = this.http.get<UserInfo>(`${userAPIUrl}/getCurrentUserInfo`);
      const user = await firstValueFrom(req);

      if (!user) return;

      this.#userInfo.set(user);
      setUser(user);
      localStorage.setItem(StorageKey.UserInfo, JSON.stringify(user));
    } catch (error) {
      this.navController.navigateRoot('/login');
    }
  }
}
