import * as Sentry from '@sentry/angular';
import { environment } from '@environment';
import { UserInfo } from '@models/user-info.model';
import { ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { version } from '../package.json';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://ef7a74b3094fc29be9cffd3d002f1f7f@sentry.moveo.technology/6',
    // release: version,
    // dist: version,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /https:\/\/ops\.moveo\.net/,
      /https:\/\/sandbox\.moveo\.net/,
      /https:\/\/ops-dev\.moveo\.net/,
      /https:\/\/fuse\.moveo\.net/,
      /https:\/\/dispatch-api-dev\.moveo\.net/,
      /https:\/\/mds-staging\.moveo\.net/,
      /http:\/\/dispatch-api\.com/,
      /http:\/\/mds\.moveo\.ar/,
      /http:\/\/mds\.drvn\.ar/,
    ],
    environment: environment.production ? 'production' : 'staging',
    replaysSessionSampleRate: environment.production ? 1.0 : 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const setUser = (user: UserInfo) => {
  if (!user) {
    Sentry.setUser(null);
    return;
  }
  Sentry.setUser({
    id: user.id,
    username: `${user.first_name}_${user.last_name}`,
    email: user.email,
  });
};

export const SENTRY_PROVIDERS = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];
